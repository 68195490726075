<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input class="width_150" v-model="search.id" placeholder="请输入听书ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">标题：</span>
        <el-input class="width_250" v-model="search.title" placeholder="请输入标题关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">专辑标题：</span>
        <el-input class="width_250" v-model="search.up_title" placeholder="请输入标题关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select class="width_100" v-model="search.status" placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <br>
      <div class="search-box">
        <el-button type="primary" size="medium" class="el-icon-tickets" @click="showAudioList()" >专辑管理</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showMultiStatus">批量上/下架</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showMultiFree">批量付费</el-button>
      </div>
    </div>

    <el-table :data="tableData"  @selection-change="handelChapterSelection" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="chapter" label="章节" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="up_title" label="专辑" align="center" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="is_free" label="免费" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch disabled :active-value=1 @click="chapterFree(scope.row)" :inactive-value=0 :value="scope.row.is_free" ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.status == 0" style="color: #4f5555">未发布</span>
          <span v-if="scope.row.status == 1" style="color: #1d953f">已上架</span>
          <span v-if="scope.row.status == 2" style="color: #f15b6c">已下架</span>
        </template>
      </el-table-column>
      <el-table-column prop="addtime" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template #default="scope">
          <span class="operation" v-if="!scope.row.playing" @click="play(scope.row.url,scope.$index)">播放</span>
          <span class="operation" v-else @click="pause(scope.$index)">暂停</span>
          <span class="operation" @click="showDialogEdit(scope.row,scope.$index)">编辑</span>
          <span class="operation" @click="delChapter(scope.row,scope.$index)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <el-dialog v-model="dialogStatus" title="批量上/下架设置">
      <el-form label-width="100px">
        <el-form-item label="ID列表">
          <span>{{statusForm.ids.join(",")}}</span>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="statusForm.status">
            <el-radio :label="1" >上架</el-radio>
            <el-radio :label="2" >下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveMultiStatus" type="primary">确定</el-button>
          <el-button @click="dialogStatus=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="dialogFree" title="批量上付费设置">
      <el-form label-width="100px">
        <el-form-item label="ID列表">
          <span>{{freeForm.ids.join(",")}}</span>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="freeForm.is_free">
            <el-radio :label="1" >免费</el-radio>
            <el-radio :label="0" >收费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveMultiFree" type="primary">确定</el-button>
          <el-button @click="dialogFree=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="dialogEdit" title="编辑">
      <el-form label-width="100px">
        <el-form-item label="ID">
          <span>{{editForm.id}}</span>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="editForm.title">{{editForm.title}}</el-input>
        </el-form-item>
        <el-form-item label="章节">
          <el-input v-model="editForm.chapter">{{editForm.chapter}}</el-input>
        </el-form-item>
        <el-form-item label="专辑">
          <span>{{editForm.up_title}}</span>
        </el-form-item>
        <el-form-item label="付费">
          <el-radio-group v-model="editForm.is_free">
            <el-radio :label="1" >免费</el-radio>
            <el-radio :label="0" >收费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="0" >审核中</el-radio>
            <el-radio :label="1" >上架</el-radio>
            <el-radio :label="2" >下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveEdit" type="primary">确定</el-button>
          <el-button @click="dialogEdit=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="dialogAudio" title="听书专辑管理" width="1200px">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">标题：</span>
          <el-input class="width_150" v-model="audioSearch.title" placeholder="请输入专辑标题" size="medium" clearable></el-input>
        </div>
        <div class="search-box">
          <span class="search-box-title">分类：</span>
          <el-select class="width_150" v-model="audioSearch.type" placeholder="全部" size="medium" clearable>
            <el-option :key="-1" label="全部" :value="-1"></el-option>
            <el-option v-for="item in types" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <span class="search-box-title">完结：</span>
          <el-select class="width_100" v-model="audioSearch.is_done">
            <el-option label="全部" value="-1"></el-option>
            <el-option label="连载中" value="0"></el-option>
            <el-option label="已完结" value="1"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <span class="search-box-title">状态：</span>
          <el-select class="width_100" v-model="audioSearch.status" placeholder="全部" size="medium" clearable>
            <el-option :key="-1" label="全部" :value="-1"></el-option>
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="getAudioList()">查询</el-button>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="showMultiAudioStatus">批量上/下架</el-button>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="multiAudioDel">批量删除</el-button>
        </div>
      </div>
      <el-table :data="audioTableData"  @selection-change="handelAudioSelection" :header-cell-style="{background:'#F7F8FA'}" >
        <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="image" label="封面图" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <ximg v-if="scope.row.image !=''" width="30" :src="scope.row.image"></ximg>
          </template>
        </el-table-column>
        <el-table-column prop="chapters" label="总章节" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
<!--            <span>{{getType(scope.row.type)}}</span>-->
            <span v-for="item of scope.row.type" :key="item">{{item.title}},</span>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="标签" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-for="item of scope.row.tags" :key="item">{{item.title}},</span>
          </template>
        </el-table-column>
        <el-table-column prop="watch_count" label="浏览量" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="is_done" label="完结?" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.is_done==0" >连载中</span>
            <span v-else style="color:green">已完结</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.status == 0" style="color: #4f5555">未发布</span>
            <span v-if="scope.row.status == 1" style="color: #1d953f">已上架</span>
            <span v-if="scope.row.status == 2" style="color: #f15b6c">已下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="addtime" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="100px" align="center">
          <template #default="scope">
            <span class="operation" @click="showDialogEditAudio(scope.row,scope.$index)">编辑</span>
            <span class="operation" @click="delAudio(scope.row,scope.$index)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <page :pager="audioPager" @query="getAudioList()" />
      </template>
    </el-dialog>

    <el-dialog v-model="dialogEditAudio" title="编辑专辑">
      <el-form label-width="100px">
        <el-form-item label="ID">
          <span>{{editAudioForm.id}}</span>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="editAudioForm.title">{{editAudioForm.title}}</el-input>
        </el-form-item>
        <el-form-item label="章节">
          <el-input v-model="editAudioForm.chapters">{{editAudioForm.chapters}}</el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="editAudioForm.type" multiple clearable>
            <el-option v-for="item of types" :key="item" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签">
          <el-select v-model="editAudioForm.tags" multiple clearable>
            <el-option v-for="item of tags" :key="item" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="浏览量">
          <el-input v-model="editAudioForm.watch_count">{{editAudioForm.watch_count}}</el-input>
        </el-form-item>
        <el-form-item label="完结?">
          <el-radio-group v-model="editAudioForm.is_done">
            <el-radio :label="1" >已完结</el-radio>
            <el-radio :label="0" >连载中</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="editAudioForm.status">
            <el-radio :label="0" >审核中</el-radio>
            <el-radio :label="1" >上架</el-radio>
            <el-radio :label="2" >下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveEditAudio" type="primary">确定</el-button>
          <el-button @click="dialogEditAudio=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="dialogAudioStatus" width="500px" title="批量上/下架设置">
      <el-form label-width="100px">
        <el-form-item label="ID列表">
          <span>{{audioStatusForm.ids.join(",")}}</span>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="audioStatusForm.status">
            <el-radio :label="1" >上架</el-radio>
            <el-radio :label="2" >下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveMultiAudioStatus" type="primary">确定</el-button>
          <el-button @click="dialogAudioStatus=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import httpClient from '@/config/httpClient.js'
import page from "@/components/page";
import XPlayer from '@/utils/XPlayer.js';
export default {
  name: "community_audio",
  components: {
    page,
  },
  data() {
    return {
      editAudioIndex:0,
      editAudioForm:{
        id:0,
        title:"",
        status:0,
        is_done:0,
        chapters:0,
        type:0,
        tags:[],
        watch_count:0,
      },
      dialogEditAudio:false,
      dialogAudio:false,
      dialogAudioStatus:false,
      audioTableData:[],
      audioPager: {total: 0, page: 1, rows: 20},
      pager: {total: 0, page: 1, rows: 20},
      audioSearch:{
        title:"",
        status:-1,
        is_done:"-1",
        type:-1,
      },
      search: {
        //搜索
        title: "", //时间
        id:"",
        status:-1,
        up_title:""
      },
      audioSelections:[],
      audioStatusForm:{
        ids:[],
        status:1,
      },
      statusForm:{
        ids:[],
        status:1,
      },
      dialogStatus:false,
      freeForm:{
        ids:[],
        is_free:1,
      },
      dialogFree:false,
      statusList:[
        {value:0,label:"审核中"},
        {value:1,label:"上架"},
        {value:2,label:"下架"},
      ],
      tableData:[],
      selections:[],
      editIndex:0,
      editForm:{},
      dialogEdit:false,
      tags:[],
      types:[],
      player:null,
      playerIndex:-1,
    };
  },
  mounted() {
    this.getList()
    // this.getAudioList()
    this.getTypes()
    this.getTags()
  },
  methods:{

    play(url,index){
      this.tableData[index]['playing'] = true
      console.log("播放:",url)
      if (this.player==null){
        this.player =new XPlayer({})
      }else{
        this.player.pause()
      }
      this.player.src(url)
      this.player.play()
    },

    pause(index){
      this.tableData[index]['playing'] = false
      if (this.player)this.player.pause()
    },

    delAudio(row,index){
      this.$confirm("删除听书专辑: " + row.title + "吗？").then(()=> {
        this.$common.showLoading()
        httpClient("delAudio").post({ids:row.id}).then(res=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.audioTableData.splice(index,1)
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    getTagByIds(ids){
      var tags = []
      for(const id of ids.split(",")){
        var tag = this.getTag(id)
        if (tag){
          tags.push(tag)
        }
      }
      return tags
    },
    getTypeByIds(ids){
      var tags = []
      for(const id of ids.split(",")){
        var tag = this.getTypeById(id)
        if (tag){
          tags.push(tag)
        }
      }
      return tags
    },
    getTypeById(id){
      for(const t of this.types){
        if (t.id == id){
          return t
        }
      }
      return null
    },
    getType(type){
      if (type == 0){
        return "未分类"
      }
      for(const t of this.types){
        if (t.id == type){
          return t.title
        }
      }
      return "未知分类:"+type
    },
    getTypes(){
      httpClient("communityType").post({type:3}).then((res)=>{
        if (res.code == 0){
          this.types = res.data.list
        }else{
          this.$message({message:"读取漫画分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getTag(id){
      for(const tag of this.tags){
        if (tag.id == id){
          return tag
        }
      }
      return null
    },
    getTags(){
      httpClient("getTags").post({type:4}).then((res)=>{
        if (res.code == 0){
          this.tags = res.data
        }else{
          this.$message({message:"读取听书标签失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    saveEditAudio(){
      this.$common.showLoading()
      let form = this.editAudioForm
      form.tags = this.editAudioForm.tags.join(",")
      form.type = this.editAudioForm.type.join(",")
      console.log(form)
      httpClient("editAudio").post(form).then(res=>{
        this.$common.hideLoading()
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogEditAudio = false
          for(var key in this.editAudioForm ){
            this.audioTableData[this.editAudioIndex][key] = this.editAudioForm[key]
          }
          this.audioTableData[this.editAudioIndex].tags = this.getTagByIds(this.editAudioForm.tags)
          console.log(this.getTypeByIds(this.editAudioForm.type))
          this.audioTableData[this.editAudioIndex].type = this.getTypeByIds(this.editAudioForm.type)
        }
      })
    },
    showDialogEditAudio(row,index){
      var tagids = []
      var types = []
      if (row.tags){
        for(const tag of row.tags){
          tagids.push(tag.id)
        }
      }
      if (row.type){
        for(const t of row.type){
          types.push(t.id)
        }
      }
      this.editAudioForm = {
        id: row.id,
        title:row.title,
        type:types,
        tags:tagids,
        status:row.status,
        is_done:row.is_done,
        chapters:row.chapters,
        watch_count: row.watch_count,
      }
      this.editAudioIndex = index
      this.dialogEditAudio = true
    },
    showAudioList(){
      this.dialogAudio = true
      this.getAudioList()
    },
    getAudioList(){
      var _param = {
        page:this.audioPager.page,
        row:this.audioPager.rows
      }
      _param = {..._param,...this.audioSearch}
      httpClient("getAudioList").post(_param).then((res)=>{
        if (res.code == 0){
          this.audioTableData = res.data.list
          if (this.audioPager.page == 1){
            this.audioPager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    saveEdit(){
      this.$common.showLoading()
      httpClient("editAudioChapter").post(this.editForm).then(res=>{
        this.$common.hideLoading()
        this.dialogEdit = false
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          for(var key in this.editForm ){
            this.tableData[this.editIndex][key] = this.editForm[key]
          }
        }
      })
    },
    showDialogEdit(row,index){
      this.editForm = row
      this.editIndex = index
      this.dialogEdit = true
    },
    delChapter(row,index){
      this.$confirm("删除听书章节: " + row.title + "吗？").then(()=> {
        this.$common.showLoading()
        httpClient("delAudioChapter").post({ids:row.id}).then(res=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.tableData.splice(index,1)
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })

    },
    handelAudioSelection(rows){
      this.audioSelections = rows
    },
    handelChapterSelection(rows){
      this.selections = rows
    },

    saveMultiFree(){
      this.$common.showLoading()
      httpClient("setAudioChapterFree").post({ids:this.freeForm.ids.join(","),is_free:this.freeForm.is_free}).then(res=>{
        this.$common.hideLoading()
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogFree = false
          this.getList()
        }
      })
    },
    saveMultiStatus(){
      this.setStatus(this.statusForm.ids.join(","),this.statusForm.status)
    },
    saveMultiAudioStatus(){
      this.$common.showLoading()
      httpClient("setAudioStatus").post({ids:this.audioStatusForm.ids.join(","),status:this.audioStatusForm.status}).then(res=>{
        this.$common.hideLoading()
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogAudioStatus = false
          this.getAudioList()
        }
      })
    },
    setStatus(ids,status){
      this.$common.showLoading()
      httpClient("setAudioChapterStatus").post({ids:ids,status:status}).then(res=>{
        this.$common.hideLoading()
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogStatus = false
          this.getList()
        }
      })
    },
    showMultiFree(){
      if (this.selections.length == 0){
        return this.$message({message:"请选择章节",type:"error"})
      }
      var ids = []
      for(const row of this.selections){
        ids.push(row.id)
      }
      this.freeForm.ids = ids
      this.freeForm.is_free = 1 // 0收费 1免费
      this.dialogFree = true
    },
    multiAudioDel(){
      if (this.audioSelections.length == 0){
        return this.$message({message:"请选择专辑",type:"error"})
      }
      var ids = []
      for(const row of this.audioSelections){
        ids.push(row.id)
      }
      this.$confirm("删除听书专辑: " + ids.join(",") + "吗？").then(()=> {
        this.$common.showLoading()
        httpClient("delAudio").post({ids:ids.join(",")}).then(res=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.getList()
            this.getAudioList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    showMultiAudioStatus(){
      if (this.audioSelections.length == 0){
        return this.$message({message:"请选择专辑",type:"error"})
      }
      var ids = []
      for(const row of this.audioSelections){
        ids.push(row.id)
      }
      this.audioStatusForm.ids = ids
      this.audioStatusForm.status = 1
      this.dialogAudioStatus = true
    },
    showMultiStatus(){
      if (this.selections.length == 0){
        return this.$message({message:"请选择章节",type:"error"})
      }
      var ids = []
      for(const row of this.selections){
        ids.push(row.id)
      }
      this.statusForm.ids = ids
      this.statusForm.status = 1
      this.dialogStatus = true
    },
    getList(){
      var _param = {
        page:this.pager.page,
        row:this.pager.rows
      }
      _param = {..._param,...this.search}
      httpClient("getAudioChapterList").post(_param).then((res)=>{
        if (res.code == 0){
          let data = res.data.list
          for(var i in data){
            data[i].playing = false
          }
          this.tableData = data
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    }
  }
}
</script>

<style>
</style>