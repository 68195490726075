class XPlayer{
    constructor(option,ready) {
        if (option){
            this.option = {...this.option,...option}
        }
        this.__initSetting()
        this.__initAudio()
        if (ready){
            var self = this
            setTimeout(()=>{
                ready(self)
            },50)
        }
    }
    option = {
        loop:false,
        url:"",
    }
    // __seekDuraion = 0; // 开始秒数
    // __seekIndex = -1; // 开始索引
    __playlist = [];
    __sources=[] // 播放对象列表
    __context=null//上下文
    __curIndex=0// 当前播放索引
    __curSource=null // 当前播放对象
    __duration=0 // 时长
    __paused=false // true暂停
    __eventlist = {} // 事件列表
    __destory=false
    __playTimer=null
    __tryMax=100 // 10秒
    __loadingTimer = null ; //加载的定时器
    __initSetting  (){
        this.__playlist = []
        this.__curIndex = 0
        this.__curSource = null
        this.__sources = []
    }
    __initAudio (){
        this.__context = new (window.AudioContext || window.webkitAudioContext)();
        if (this.__context==null){
            console.log("您的浏览器不支持audioContext")
        }
    }
    destroy = ()=>{
        this.__initSetting()
        this.__eventlist = {}
        this.__duration = 0
        this.__destory = true
        this.__playTimer = null
        this.__loadingTimer = null
    }
    duration = ()=>{
        return this.__duration
    }
    play = async ()=>{
        if(this.__paused){
            this.__curSource.resume()
            this.__paused = false
            return
        }
        this.__paused = false
        if (this.option.url){
            if (this.__playlist.length === 0){
                this.__initSetting()
                await this.__src(this.option.url) // 载入playlist
                if (this.__playlist.length === 0){
                    console.log("读取播放列表失败")
                }
            }
            this.__loadSource(this.__curIndex) // 从当前索引开始加载buffer
            this.__play(0) // 实际播放
        }else{
            console.log("播放失败,播放地址为空")
            this.emit("error")
        }
    }
    __play(duration=0,tryCount=0){
        if (this.__destory)return
        if (tryCount >= this.__tryMax && this.__curIndex < this.__playlist.length-1){
            console.log("播放失败,当前没有可以播放的贞")
            this.__playTimer&&clearTimeout(this.__playTimer)
            this.emit("pause")
        }else{
            if (this.__curIndex > 0 && this.__curIndex > this.__sources.length-1){
                console.log("播放结束")
                this.emit("ended")
                return
            }
            this.__curSource = this.__sources[this.__curIndex]
            console.log("当前播放贞:",this.__curIndex,this.__curSource)
            if(!this.__curSource){
                this.__loadSource(this.__curIndex)
                this.__playTimer = setTimeout(()=>{
                    this.emit("loading") // 加载中
                    this.__play(duration,tryCount+1)
                },100)
            }else{
                this.__curSource.onended = ()=>{
                    this.__playNext()
                }
                this.__curSource.start(duration)
                this.__playTimer&&clearTimeout(this.__playTimer)
                console.log("开始播放咯")
                this.emit("playing")
            }
        }
    }
    __playNext(){
        if (this.__paused)return
        this.__curIndex += 1
        if (this.__curIndex > this.__playlist.length-1){
            // 播放完了最后一贞
            if (this.option.loop){
                this.__curIndex = 0
            }
            setTimeout(()=>{
                this.__play()
            },100)
        }else{
            this.__play()
        }
    }
    // 事件触发
    emit = (event)=>{
        if (this.__eventlist[event]){
            this.__eventlist[event]()
        }
    }
    // 事件侦听
    on = (event,func)=>{
        this.__eventlist[event] = func
    }
    // 点播
    seek = (duration)=>{
        if (this.__playlist.length === 0){
            // 还未加载，等待加载
            return
        }
        this.pause() // 暂停 切换curIndex之后，再播放
        this.emit("seek")
        var selectIndex = -1
        for(var i in this.__playlist){
            let play = this.__playlist[i]
            if (play.duration <= duration){
                duration -= play.duration
            }else{
                selectIndex = i
            }
        }
        if (selectIndex > -1){
            // 选择中当前的index duration是剩余的秒数
            this.__curIndex = selectIndex
            this.__play(duration)
        }
    }
    // 暂停
    pause = ()=>{
        if (this.__paused)return;
        if (this.__curSource){
            this.__paused = true
            this.__curSource.stop(0)
            this.emit("pause")
        }
    }
    async __src(url){
        try{
            const res = await fetch(url)
            const content = await res.text()
            this.__parseSrcContent(url,content)
        }catch (e){
            console.log(e.toString())
        }
    }
    // 设置播放源
    src = (url)=>{
        this.__initSetting() // 切换播放源, 清理掉所有的配置
        this.__initAudio()
        this.__loadingTimer = null
        this.__playTimer = null
        this.option.url = url
    }
    __parseSrcContent(url,content){
        const lastIndex = url.lastIndexOf("/")
        const url_prefix = url.substring(0,lastIndex)
        const lines = content.split("\n")
        let use_lines = []
        for(var i in lines){
            let line = lines[i]
            if (line.startsWith("#EXT-X-") || line.startsWith("#EXTM3U")){
                continue
            }
            use_lines.push(line)
        }
        var playlist = [];
        var durations = 0
        for(var j = 0;j<use_lines.length;j+=2){
            if (!use_lines[j]){
                continue
            }
            let duration = this.__parseDuration(use_lines[j])
            if (!use_lines[j+1]){
                continue
            }
            let url = use_lines[j+1]
            durations += duration
            let play = {
                duration:duration, // 分片时间
                url:url_prefix+"/"+url, // url
                loading:false, // 是否加载中
                loading_time:0, // 加载时间，用于加载超时检测
            }
            playlist.push(play)
        }
        this.__duration = durations
        this.__playlist = playlist
    }

    __parseDuration(line){
        return parseFloat(line.substring(8,line.length-1).trim()).toFixed(4)
    }
    async __loadSource(index=0){
        this.__loadingTimer&&clearTimeout(this.__loadingTimer)
        if (this.__destory)return
        if (this.__sources[index] !== undefined){
            this.__loadingTimer = setTimeout(()=>{
                this.__loadSource(index+1)
            },100)
        }else{
            // 检测是否加载完毕
            if (this.__playlist[index] === undefined)return
            let play = this.__playlist[index]
            let nowTime = (new Date()).getTime() // 超过10秒,需要重新加载
            if (play.loading && play.loading_time + 10*1000 < nowTime){
                // 防止重复加载同一分片
                return
            }
            this.__playlist[index].loading = true //
            this.__playlist[index].loading_time = nowTime // 防止重复加载同一分片
            try{
                const source = this.__context.createBufferSource()
                const response = await fetch(play.url)
                const arrayBuffer = await response.arrayBuffer()
                const audioBuffer = await this.__context.decodeAudioData(arrayBuffer)
                source.buffer = audioBuffer
                source.connect(this.__context.destination)
                this.__sources[index] = source
                this.__playlist[index].loading = false
                this.__loadingTimer = setTimeout(()=>{
                    this.__loadSource(index+1)
                },100)
            }catch (e){
                console.log("加载source失败:"+e.toString())
            }
        }
    }
}
export default XPlayer